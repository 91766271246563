
import { useRouter } from "vue-router";
import { defineComponent, ref } from "vue";
import Storage from "@/misc/Storage";
import Alert from "@/misc/Alert";

export default defineComponent({
  setup() {
    const router = useRouter();
    const leftDrawerOpen = ref(false);
    const admin = ref("Seller")

    const toggleLeftDrawer = () => {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    };

    const logout = async (): Promise<void> => {
      await Alert.Confirmation({
        type: "question",
        btnTxt: "Yes",
        title: "Are you sure wanna logout?",
      }).then((isLogout) => {
        if (isLogout) {
          Storage.removeAuthCredential();
          router.push("login");
        }
      });
    };

    const isAdmin = () => {
      if (Storage.getIsAdmin()) {
        admin.value = "Admin";
      }
    };

    isAdmin();

    return { admin, logout, leftDrawerOpen, toggleLeftDrawer };
  },
});
